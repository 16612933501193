
<script>
  export default {
    data: () => ({ 
      items:
      
        [ 
            {
                title : "DIFUSORES EN VARILLAS"
                ,preguntas: [
                    {
                        name: '1.¿Cómo se utilizan?'
                        ,values: [
                            "PASO 1. Retira el producto del empaque (En caso de difusores en presentación de 30ml, la esencia se retira por la parte inferior y las varillas por la parte superior)"
                            ,"PASO 2. Retirar tapón de aluminio reciclable"
                            ,"PASO 3. Retirar tapón de seguridad"
                            ,"PASO 4. Colocar tapón de aluminio reciclable"
                            ,"PASO 5. Insertar varillas"
                        ]
                    },
                    {
                        name: '2.¿De qué están hechas las varillas?'
                        ,values: [
                            "Las varillas están hechas a base de caña natural"
                        ]
                    }, 
                    {
                        name: '3.¿Cuál es la función de las varillas?'
                        ,values: [
                            "Las varillas regulan la intensidad del producto:"
                            ,"Intensidad leve: use de 1 a 3 varillas"
                            ,"Intensidad leve: use de 4 a 5 varillas"
                            ,"Intensidad leve: use de 7 a 9 varillas"
                        ]
                        
                    },
                    {
                        name: '4.¿Las varillas se cambian?'
                        ,values: [
                            "Si, el consumidor deberá dar la vuelta a las varillas cada 7 días para una mejor difusión del aroma."
                            ,"Transcurridos 30 días, el consumidor deberá reemplazar las varillas por unas nuevas."
                        ]
                    }
                ] 
            },
            
             {
                title : "DIFUSOR ULTRASONICO"
                ,preguntas: [
                    {
                        name: '1.¿Cómo se utilizan?'
                        ,values: [
                            "PASO 1. Abre el difusor"
                            ,"PASO 2. Añade agua máx. 200 ml (No exceder la línea del tanque de agua)"
                            ,"PASO 3. PASO 3. Añade tu esencia favorita (De 3 a 5 gotas, acorde a la intensidad del aroma que se desee) Por favor tenga cuidado de no verter agua fuera de la taza"
                            ,"PASO 4. Cierra tu difusor"
                            ,"PASO 5. Conecta el adaptador AC"
                            ,"PASO 6 A. Presiona el botón temporizador/niebla para comenzar a rociar"
                            ,"PASO 6 B. Mantén presionado el botón de luz para encender la luz"
                        ]
                    },
                    {
                        name: '2.¿Cuánto tiempo dura la esencia en el difusor?'
                        ,values: [
                            "Depende de la cantidad de esencia que se coloque en el difusor y el tiempo para el quese programe."
                        ]
                    },
                    {
                        name: '3.¿Qué tipos de productos se pueden utilizar?'
                        ,value: [
                            "Puede utilizar cualquiera de nuestra gama de repuestos para difusores:"
                            ,"Aromatizante Kit 6 Blend Essences Repuestos P/Difusor 10 Ml World Edition (P15UN0842)"
                            ,"Aromatizante Kit 8 Essential Repuestos P/Difusor 10 Ml Oils Aromatherapy(P15UN0843)"
                            ,"Aromatizante Kit 6 Blend Essences Repuestos P/Difusor 10 Ml Unforgettable Edition (P15UN0844)"
                            ,"Aromatizante Kit 6 Blend Essences Repuestos P/Difusor 10 Ml Al-Andalus Edition (P15UN0845)"
                        ]
                    } 
                ] 
            },
            
            {
                title: "REPUESTOS PARA DIFUSORES (AROMATHERAPY / AL-ANDALUS / UNFORGETTABLE / WORLD EDITION)"
                ,preguntas: [
                    {
                        name: '1.¿De dónde se obtienen?'
                        ,values: [
                            "El proceso de extracción de los aceites esenciales varía según la planta que se desee obtener."
                            ,"Partiendo de esta premisa, podemos identificar 4 métodos de extracción que se utilizan actualmente"
                            ,"Destilación: A través de agua o corriente de vapor, es una de las técnicas más utilizadas."
                            ,"Maceración: Al impregnar el aroma de las flores en marcos con grasa, se obtiene una pomada perfumada que al mezclarse con alcohol se obtiene el aceite esencial. "
                            ,"Extracción: Este es el sistema más lento y costoso, que utiliza solvente a temperatura ambiente y alcohol para obtener el aceite."
                            ,"Prensado: Muy habitual en la extracción de cítricos. Actualmente se utilizan máquinas que ejercen presión sobre los frutos."
                        ]
                    },
                    {
                        name: '2.¿Cómo se usan?'
                        ,values: [
                            "Los aceites esenciales están especialmente diseñados para su uso en difusores y purificadores de aire, NO deben ser utilizados directamente en la piel. "
                            ,"PASO 1. Abre el difusor"
                            ,"PASO 2. Añade agua (No exceder la línea del tanque de agua)"
                            ,"PASO 3. Añade tu esencia favorita (De 3 a 5 gotas cada 200 ml, acorde a la intensidad del aroma que se desee) Por favor tenga cuidado de no verter agua fuera de la taza  "
                            ,"PASO 4. Cierra tu difusor"
                            ,"PASO 5. Presiona el botón de encendido"
                        ]
                    },
                    {
                        name: '3.¿Cuál es su duración?'
                        ,values: [
                            "El juego de aceites esenciales tendrá una vida útil de aproximadamente 2 años desde su apertura siempre y cuando se almacenen adecuadamente en un lugar oscuro y fresco, a temperaturas no superiores a 20 °"
                            
                        ]
                    },
                    {
                        name: '4.¿Qué beneficios tienen?'
                        ,values: [
                            "Los aceites esenciales de Air Natur están diseñados para brindarle todos sus beneficios, algunos de ellos son:  "
                            ,"Anti estrés"
                            ,"Anti fatiga"
                            ,"Ayuda a la autoestima"
                            ,"Ayuda a controlar la ansiedad"
                            ,"Tiempo de relajación"
                            ,"Disminuye los dolores musculares"
                            ,"Contrarresta los dolores"
                            ,"Ayuda a dormir mejor"
                            ,"Purifica el aire"
                            ,"Olor agradable"
                        ]
                    },
                    {
                        name: '5.¿Qué precauciones se deben tener con el producto?'
                        ,values: [
                            "Evite el contacto con ojos y piel. No comer. Manténgase fuera del alcance de los niños. Almacenar las esencias en un lugar seco que no supere los 20°C."
                        ]
                    }

                ]
            },

            {
                title: "DIFUSOR ULTRASONICO"
                ,preguntas: [
                    {
                        name: '1.¿De qué está compuesto?'
                        ,values: [
                            "Lo componen 3 tacos de madera de un material muy ligero y absorbente, lo que permite que esté dosificada la lata con 30 gramos de esencia."
                        ]
                    },
                    {
                        name: '2.¿Cómo se usan?'
                        ,values: [
                            "PASO 1. Se retira la tapa superior"
                            ,"PASO 2. Se retira el probador"
                            ,"PASO 3. Coloque la lata en dirección a usted"
                            ,"PASO 4. Coloque su dedo índice en la parte superior de lata, presione y tire suavemente en dirección a usted"
                            ,"PASO 5. Coloque la tapa superior"
                        ]
                    },
                    {
                        name: '3.¿Se puede regular la intensidad? ¿Cómo?'
                        ,values: [
                            "Si, gracias a la tapa en forma de ventila usted tiene la posibilidad de regular la abertura del producto y así regular la intensidad del aroma."
                        ]
                    },
                    {
                        name: '4.¿Cuál es su duración?'
                        ,values: [
                            "La duración de nuestro producto es de 2 a 3 meses."
                        ]
                    }

                ]
            },
            {
                title: "AROMATIZANTES SPRAY"
                ,preguntas: [
                    {
                        name: '1.¿Cómo se usan?'
                        ,values: [
                            "PASO 1. Agite el spray"
                            ,"PASO 2. Retire la tapa"
                            ,"PASO 3. Pulverizar 2 o 3 veces en el espacio o en la superficie al menos a 20 centímetros de distancia."
                            ,"PASO 4. Coloque la tapa nuevamente"
                        ]
                    },
                    {
                        name: '2.¿Cuál es su duración?'
                        ,values: [
                            "Su duración es de 20 a 30 min."
                        ]
                    }
                ]
            },
            {
                title: "NEUTRALIZADOR DE OLORES/ELIMINADOR DE OLORES"
                ,preguntas: [
                    {
                        name: '1.¿Cómo funciona?'
                        ,values: [
                            "Este producto, desprende moléculas activas que atrapan los olores desagradables y las partículas de mal olor haciéndolos desaparecer inmediatamente. No enmascara o camufla el olor, sino que lo erradica."
                        ]

                    },
                    {
                        name: '2.¿Cómo se usan?'
                        ,values: [
                            "En el caso de los Neutralizadores de Olores:"
                            ,"PASO 1. Agite el spray"
                            ,"PASO 2. Retire la tapa"
                            ,"PASO 3. Pulverizar 2 o 3 veces en el espacio o en la superficie al menos a 20 centímetros de distancia."
                            ,"PASO 3 A. Para olores muy persistentes, repetir la operación."
                            ,"PASO 4. Coloque la tapa nuevamente"
                            ," "
                            ,"En el caso de los Eliminadores de Olores:"
                            ,"PASO 1. Verter el producto en un pulverizador o atomizador."
                            ,"PASO 2. Pulverizar el producto en el ambiente directamente en el foco del mal olor. (El olor queda eliminado al instante)"
                            ,"PASO 3. Para olores muy persistentes, repetir la operación. (En materiales sensibles, efectuar una prueba en una muestra.)"
                        ]
                    },
                    {
                        name: '3.¿Qué precauciones se debe tener con el producto?'
                        ,values: [
                            "Se debe tener cuidado al momento de rociar el producto ya que provoca irritación ocular grave, en caso de contacto con los ojos: enjuagar cuidadosamente con agua durante varios minutos. Quitar los lentes de contacto, si lleva y resulta fácil. Seguir enjuagando."
                            ,"En caso de contacto con la piel: Quitar inmediatamente todas las prendas contaminadas, enjuagar la piel con agua/ducharse."
                            ,"Mantener alejado del calor, de superficies calientes, de chispas, de llamas abiertas y de cualquier otra fuente de calor.  "
                            ,"Mantener fuera del alcance de los niños."
                            ,"Lavarse concienzudamente tras la manipulación."
                        ]
                    }
                ]
            }, 
            {
                title: "LITTLE BOX/LITTLE BOX VENT"
                ,preguntas: [
                    {
                        name: '1.¿De qué está compuesto?'
                        ,values: [
                            "La tecnología de este difusor concentra el perfume sobre una base sólida, lo que elimina el riesgo de derrame dentro de su automóvil. Es óptimo para su uso en el retrovisor de su automóvil."
                        ]
                    },
                    {
                        name: '2.¿Cuál es su modo de uso?'
                        ,values: [
                            "PASO 1. Toma el producto y remueve la pegatina delantera"
                            ,"PASO 2. Toma el sujetador"
                            ,"PASO 3. Cuélgalo en tu retrovisor"
                        ]
                    },
                    {
                        name: '3.¿Se puede controlar la intensidad del aroma? ¿Cómo?'
                        ,values: [
                            "Si, para tener una intensidad media se desprende solo la pegatina delantera, para una intensidad mayor se desprenden ambas etiquetas (delantera y trasera)"
                        ]
                    },
                    {
                        name: '4.¿Cuál es su duración?'
                        ,values: [
                            "Contiene una gran proporción de aceite esencial puro que hace que su duración sea superior a 2 meses. "
                        ]
                    }
                ]
            },
            {
                title: "PAPER"
                ,preguntas: [
                    {
                        name: '1.¿Cómo se usan?'
                        ,values: [
                            "PASO 1. Retirar envoltura del producto"
                            ,"PASO 2. Colgar el producto en el retrovisor del automóvil"
                        ]
                    },
                    {
                        name: '2.¿Cuál es el tiempo de duración de los aromas?'
                        ,values: [
                            "Los aromas pueden durar varias semanas en el retrovisor de tu auto."
                        ]
                    }
                ]
            },
            {
                title: "FRESH NEW CAR"
                ,preguntas: [
                    {
                        name: '1.¿Cuál es su funcionalidad?'
                        ,values: [
                            "Es una gama de sprays sin gas en formato de 60 ml, de esencias concentradas que se caracterizan por unos llamativos diseños, ideales para crear un ambiente limpio y fresco en todo momento."
                            ,"Se presenta en una amplia gama de suaves perfumes de gran calidad y duración"
                        
                        ]
                    },
                    {
                        name: '2.¿Cómo se usan?'
                        ,values: [
                            "PASO 1. Agite el spray"
                            ,"PASO 2. Retire la tapa"
                            ,"PASO 3. Pulverizar 2 o 3 veces en el espacio o en la superficie al menos a 20 centímetros de distancia"
                            ,"PASO 4. Coloque la tapa nuevamente"
                        ]
                    },
                ]
            },
            {
                title: "ORGANIC VENT"
                ,preguntas:[
                    {
                        name: '1.¿De qué está compuesto?'
                        ,values: [
                            "Organic Vent concentra sus 5 gramos de esencia en una base sólida de fibra de madera compacta que elimina cualquier riesgo de derrame en el coche. "
                        ]
                    },
                    {
                        name: '2.¿Cuál es la diferencia entre Organic Vent y Organic?'
                        ,values: [
                            "La diferencia se basa en el tamaño compacto de cada uno, para Organic Vent se concentran 5 gramos de esencia mientras que Organic concentra 30 gramos de esencia."
                        ]
                    },
                    {
                        name: '3.¿Cómo se utiliza?'
                        ,values: [
                            "PASO 1. Toma el producto y remueve la pegatina delantera"
                            ,"PASO 2. Remueve la pinza ubicada en la parte superior"
                            ,"PASO 3. Introduce la pinza en la parte trasera del producto"
                            ,"PASO 4. Coloca el producto en las ventilas de tu automóvil</li>"
                        ]
                    },
                    {
                        name: '4.¿Se puede controlar la intensidad del aroma? ¿Cómo?'
                        ,values: [
                            "Si, para tener una intensidad media se desprende solo la pegatina delantera, para una intensidad mayor se desprenden ambas etiquetas (delantera y trasera) "
                        ]
                    },
                    {
                        name: '5.¿Qué lo hace diferente de los mismos tipos de productos que hay en el mercado?'
                        ,values: [
                            "Organic Vent es el difusor compacto más moderno del mercado. Gracias a su sistema de difusión mundialmente patentado, la fragancia se distribuye por el automóvil de manera regular y efectiva.  "
                            ,"Su práctica y potente pinza lo convierten en una manera óptima de ambientación para la ventilación del automóvil."                            
                        ]
                    },
                    {
                        name: '6.¿Cuál es la duración del producto?'
                        ,values: [
                            "Asegura una duración superior a los 30 días de difusión."
                        ]
                    }
                ]
            },
            {
                title: "LITTLE BOTTLE/AIR CAR/AIR NATUR BOTELLITA"
                ,preguntas: [
                    {
                        name: '1. ¿Qué son?'
                        ,values: [
                            "Son un sistema de difusión patentado mundialmente para una distribución del aroma constante y paulatina."
                        ]
                    },
                    {
                        name: '2.¿Cuál es su uso correcto?'
                        ,values: [
                            "PASO 1. Desenrosque el tapón de madera"
                            ,"PASO 2. Retire el tapón de seguridad"
                            ,"PASO 3. Vuelva a enroscar el tapón de madera"
                            ,"PASO 4. Dé la vuelta al frasco durante unos segundos hasta que se empape el cordón."
                            ,"PASO 5. Coloque la botella en el espejo retrovisor del coche"
                            ,"PASO 6. Asegure su botella correctamente para evitar derrames o caídas."
                        ]
                    },
                    {
                        name: '3.¿La tapa de madera da algún beneficio extra?'
                        ,values: [
                            "Su sistema garantiza un cierre seguro que evita derrames durante su uso."
                        ]
                    },
                    {
                        name: '4.¿Cuál es su duración?'
                        ,values: [
                            "Asegura una duración superior a los 30 días de difusión."
                        ]
                    }
                ]
            },
            {
                title: "ELITE PERFUME"
                ,preguntas: [
                    {
                        name: '1.¿Cómo funcionan?'
                        ,values: [
                            "PASO 1. Retire la tapa"
                            ,"PASO 2. Pulverizar 2 o 3 veces en el espacio o persona al menos a 20 centímetros de distancia."
                            ,"PASO 3. Coloque la tapa nuevamente"
                        ]
                    },
                    {
                        name: '2.¿Qué usos se les puede dar?'
                        ,values: [
                            "Se puede usar como aromatizante para tu auto y de uso personal."
                        ]
                    },
                    {
                        name: '3.¿Cuál es la duración de la fragancia?'
                        ,values: [
                            "Su duración es de 20 a 30 min."
                        ]
                    }
                ]
            },
            {
                title: "COLECCIÓN/PASIÓN"
                ,preguntas: [
                    {
                        name: '1.¿Por qué solo incluyen 5 frascos?'
                        ,values: [
                            "La razón por la que se incluyen cinco ambientadores en varillas en cada colección es porque consideramos este número de difusores los necesarios para aromatizar una casade tamaño mediano. "
                            ,"El cliente puede distribuirlos por los espacios clave de su hogar. Un ambientador de mayor tamaño difunde buen olor en un espacio, pero son varios de pequeño tamaño los que mantienen una buena aromatización."
                        ]
                    },
                    {
                        name: '2.¿Cómo se usan?'
                        ,values: [
                            "PASO 1. Retira el producto del empaque"
                            ,"PASO 2. Retirar tapón de aluminio reciclable"
                            ,"PASO 3. Retirar tapón de seguridad"
                            ,"PASO 4. Colocar tapón de aluminio reciclable"
                            ,"PASO 5. Insertar varillas"
                        ]
                    },
                    {
                        name: '3.¿Cuáles son sus recomendaciones y precauciones?'
                        ,values: [
                            "Cada 3 días dar vuelta a las varillas."
                            ,"Colocar en un lugar medio-alto con corriente de aire."
                            ,"Evitar su uso en sitios húmedos."
                            ,"Utilizar fuera del alcance infantil."
                        ]
                    }
                ]
            },
            {
                title: "VELA AROMATICA"
                ,preguntas: [
                    {
                        name: '1.¿De qué están hechas?'
                        ,values: [
                            "Nuestras velas están hechas a base de cera 100% de soja Y 0% alcohol"
                        ]
                    },
                    {
                        name: '2.¿Qué es la soja?'
                        ,values: [
                            "La soja, mejor conocida como soya, es una legumbre rica en proteínas que aparte de su alto número de aminoácidos ayuda a reducir la probabilidad de sufrir enfermedades crónicas."
                        ]
                    },
                    {
                        name: '3.¿Cómo se usan?'
                        ,values: [
                            "PASO 1. Retira el producto del empaque"
                            ,"PASO 2. Retira la tapa de madera"
                            ,"PASO 3. Levanta la mecha"
                            ,"PASO 4. Enciende la vela"
                        ]
                    },
                    {
                        name: '4.¿Qué las hace diferentes de las demás velas?'
                        ,values: [
                            "Nuestras velas están caracterizadas por su exquisito aroma de esencia natural, su composición de soja, la mecha natural hecha con filamentos de algodón y su fina presentación en un frasco de vidrio.  "
                        ]
                    },
                    {
                        name: '5.¿Cuáles son sus beneficios?'
                        ,values: [
                            "La cera de soja es un material derivado de plantas, una fuente renovable y biodegradable creando así un producto sostenible. "
                            ,"Su combustión no supone ningún riesgo para la salud, ni aumenta los niveles de toxicidad del ambiente ya que no aumenta los niveles de CO2, ni produce hollín."
                            ,"Las mechas empleadas están elaboradas con filamentos de algodón 100% naturales."
                            ,"Las velas de cera de soja se consumen más lentamente que las elaboradas con parafina, aguantando 2 o 3 veces más que estas. De este modo, resultan mucho más rentables."
                            ,"Las velas de cera de soja producen una mayor luminosidad, alumbrando con una llama viva y fuerte, pero sin peligros."
                            ,"Las velas de cera de soja de Aromas de Andalucía perfuman la estancia incluso antes de encenderla. Una vez encendida la mecha, la fragancia se fusiona perfectamente con la cera de soja liberando el aroma de manera suave y estable."
                            ,"El humo resultante de la cera de soja no afecta ni estropea los muebles ni las pintas de las paredes que rodean la vela."
                        ]
                    },
                    {
                        name: '6.¿Por qué no usar velas con parafina?'
                        ,values: [
                            "Las velas con parafina emiten vapores y gases dañinos para la salud durante su combustión."
                            ,"Aumentan los niveles de CO2."
                            ,"Permiten hollín negro desaconsejado para inhalar"
                            ,"Su humo mancha las pinturas de las paredes y los muebles que lo rodean."
                            ,"Las mechas utilizadas en estas velas no son naturales y se elabora con componentes químicos que se unen al humo perjudicial que desprenden."
                            ,"Utilizan fragancias sintéticas menos beneficios para la salud."
                            ,"Las velas de parafina no llegan a consumirse del todo, quedándose hasta un 30% de la vela sin utilizar."
                            ,"La parafina proviene del petróleo, fuente limitada y no renovable."
                            ,"La fragancia no casa lo suficiente con la parafina, por lo que la fragancia acaba desapareciendo con el tiempo."
                        ]
                    }
                ]
            },
            {
                title: "DESINFECTANTE DE MANOS"
                ,preguntas: [
                    {
                        name: '1.¿De qué está hecho?'
                        ,values: [
                            "Contiene glicerina vegetal y colágeno para una especial protección, 70% de alcohol y cloruro de benzalconio (biocida)."
                        ]
                    },
                    {
                        name: '2.¿Qué lo diferencia de los demás desinfectantes?'
                        ,values: [
                            "PASO 1. Verter el producto en un pulverizador o atomizador."
                            ,"PASO 2. Pulverizar el producto en el ambiente a desinfectar al menos a 20 centímetros de distancia"
                            ,"Tiene un secado instantáneo ya que el producto se evapora y no deja las manos pegajosas. Aumenta su hidratación de la piel después de su uso."
                        ]
                    }
                ]
            },
            {
                title: "DESINFECTANTE (SUPERFICIES/AMBIENTE)"
                ,preguntas: [
                    {
                        name: '¿De qué está compuesto?'
                        ,values: [
                            "Es un desinfectante de superficies y ambiente profesional para uso en spray que contiene un 78% de alcohol."
                        ]
                    },
                    {
                        name: '2.¿Cómo se utiliza?'
                        ,values: [
                            "Eficacia desinfectante de alta duración que proporciona un excelente efecto inmediato y una actividad antimicrobiana duradera y sostenida."
                        ]
                    },
                    {
                        name: '3.¿Qué lo diferencia de los demás desinfectantes?'
                        ,values: [
                            "Eficacia desinfectante de alta duración que proporciona un excelente efecto inmediato y una actividad virucida duradera y sostenida."
                        ]
                    }
                ]
            },
            {
                title: "ACEITES ESENCIALES"
                ,preguntas: [
                    {
                        name: '1.¿De qué están compuestos los aceites?'
                        ,values: [
                            "Son 100% naturales, es decir, nosotros un aceite esencial no lo trabajamos, viene directamente destilado de alguna zona en particular dependiendo del producto, nosotros simplemente lo que hacemos es traerlo y empaquetarlo."
                        ]
                    },
                    {
                        nmae: '2.¿Por qué se dividen en paquetes?'
                        ,values: [
                            "Nuestros paquetes se dividen según el nivel de experiencia que se tenga con los aceites esenciales, iniciando con el “Pack Starter” donde se busca que el consumidor conozca o identifique las fragancias naturales."
                            ,"Seguido del “Pack Top” donde se pueden encontrar ya esencias que todos conocemos y son reconocidas por sus beneficios en salud y belleza. "
                        ]
                    },
                    {
                        name: '3.¿Cuál es la diferencia entre los Repuestos para Difusores y los Aceites Esenciales?'
                        ,values: [
                            "Los repuestos para difusores están basados en aromas alusivos a países y ciudades específicas del mundo, además de aromas enfocados a la aromaterapia."
                            ,"Mientras que los aceites esenciales están basados en recursos naturales aprovechando cada una de sus propiedades para generar un bienestar mental y saludable. "
                        ]
                    }
                ]
            },
            {
                title: "ACEITES ESENCIALES PACK STARTER"
                ,preguntas: [
                    {
                        name: '1.¿Para qué sirven? ¿Qué beneficios tiene?'
                        ,values: [
                            "Árbol de Té:"
                            ,"Es antiséptico"
                            ,"Elimina las bacterias"
                            ,"Acelera la cicatrización"
                            ,"Estimula el sistema inmunológico"
                            ," "
                            ,"Eucalipto:"
                            ,"Mejora los problemas genitourinarios"
                            ,"Mejora la artritis reumatoide, las torceduras y la fibrosis"
                            ,"Alivia la tos y la congestión nasal"
                            ," "
                            ,"Limón:"
                            ,"Alivia la sensación de náuseas y mareos"
                            ,"Calma el malestar en el estómago"
                            ,"Disminuye las manchas de sol en la piel"
                            ," "
                            ,"Naranja Dulce:"
                            ,"Favorece los niveles de autoestima y felicidad"
                            ,"Evita los mareos"
                            ,"Evite el estreñimiento"
                            ," "
                            ,"Canela:"
                            ,"Tiene propiedades afrodisíacas"
                            ,"Estimulante"
                            ,"Da un toque acogedor a tu hogar"
                            ," "
                            ,"Romero:"
                            ,"Estimula la memoria"
                            ,"Reduce el colesterol y la anemia"
                            ,"Mejora la circulación"
                            ,"Reduce el dolor de oídos y la migraña"
                        ]
                    }
                ]
            },
            {
                title: "ACEITES ESENCIALES PACK TOP"
                ,preguntas: [
                    {
                        name: '1.¿Para qué sirven? ¿Qué beneficios tiene?'
                        ,values: [
                            "Hierba-Limón:"
                            ,"Calma la ansiedad y los nervios"
                            ,"Elimina el acné"
                            ,"Repele las picaduras de insectos"
                            ,"Desodorante apto para pieles grasas"
                            ," "
                            ,"Árbol de Té"
                            ,"Es antiséptico"
                            ,"Elimina las bacterias"
                            ,"Acelera la cicatrización"
                            ,"Estimula el sistema inmunológico"
                            ," "
                            ,"Menta:"
                            ,"Combate la migraña, el dolor de oídos y de muelas"
                            ,"Alivia la acidez, los cólicos y las flatulencias"
                            ,"Ataja la depresión, el insomnio y la irritabilidad"
                            ," "
                            ,"Eucalipto:"
                            ,"Mejora los problemas genitourinarios"
                            ,"Mejora la artritis reumatoide, las torceduras y la fibrosis"
                            ,"Alivia la tos y la congestión nasal"
                            ," "
                            ,"Lavanda:"
                            ,"Proporciona paz, tranquilidad y estabilidad"
                            ,"Favorece la desconexión mental"
                            ," "
                            ,"Naranja Dulce:"
                            ,"Favorece los niveles de autoestima y felicidad"
                            ,"Evita los mareos"
                            ,"Evite el estreñimiento"
                        ]
                    }
                ]
            }
        ] 

    }),
  }
</script>

<template> 
    <div>   
        <v-card class="mx-auto"  width="95%" >
            <br>
            <h1 class=" text-center justify-center font-weight-bold text-h5 basil--text">
                    ¿CON QUÉ TEMA QUIERES AYUDA?
            </h1> 
            <v-list> 
                <v-list-group prepend-icon="mdi-ticket" v-for="topic in items" :key="topic.title" color="basil" class="font-weight-bold basil--text">
                    <template v-slot:activator>
                        {{topic.title}}
                    </template> 
                    <v-list-group no-action  sub-group   v-for="pregunta in topic.preguntas" :key="pregunta.name">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-row>
                                <v-col>
                                    {{pregunta.name}}
                                </v-col>
                            </v-row> 
                        </v-list-item-content>
                    </template> 
                    <v-list-item v-for="respuesta in pregunta.values" :key="respuesta" >
                        <v-row>
                            <v-col>
                                {{respuesta}}
                            </v-col>
                        </v-row>  
                    </v-list-item>
                    </v-list-group>
                </v-list-group>
            </v-list>
        </v-card>  
        <br>
    </div>
</template>

 